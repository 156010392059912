.info-block {
	display: flex;
	align-items: center;
	overflow-x: hidden;
	width: 100%;
	padding-left: 16px;
	margin-bottom: 30px;

	&__container {
		display: flex;
		align-items: center;
		gap: 8px;
		align-self: stretch;
		width: 100%;
		padding-right: 16px;
	}

	&-item {
		position: relative;
		min-width: 100px;
		min-height: 100px;
		max-width: 100px;
		max-height: 100px;
		padding: 12px 0px 0px 12px;
		border-radius: 12px;
		background: var(--1, linear-gradient(180deg, #539ef9 0%, #61c5f9 100%));
		box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 0.25) inset;

		&-text {
			color: #ffffff;
			font-family: 'SF Pro Display';
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 14px;
			letter-spacing: -0.078px;
		}

		img {
			position: absolute;
			right: 0;
			bottom: 0;
			width: min-content;
			height: min-content;

		}

        &:nth-child(1) img{
            width: 67px;
            height: 61px;
        }
        &:nth-child(2) img{
            width: 66px;
            height: 70px;
        }
        &:nth-child(3) img{
            width: 46px;
            height: 46px;
        }
        &:nth-child(4) img{
            width: 43px;
            height: 48px;
        }
        &:nth-child(5) img{
            width: 67px;
            height: 61px;
        }
	}
}
